import { map } from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  WidgetTypes,
  FP_BOTTOM_PADDING,
  MODAL_TARGET_ID,
} from "legacy/constants/WidgetConstants";
import { useStickySectionOnParentScroll } from "legacy/hooks/useStickySectionOnParentScroll";
import EmptySpaceOverLay from "legacy/pages/Editor/CanvasArenas/EmptySpaceOverLay";
import PaddingOverlay from "legacy/pages/Editor/CanvasArenas/PaddingOverlay";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { APP_MODE } from "legacy/reducers/types";
import { getRoutes } from "legacy/selectors/routeSelectors";
import {
  getFocusedWidget,
  modalOrSlideoutIsOpen,
} from "legacy/selectors/sagaSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { generateClassName } from "legacy/utils/generators";
import { Flag, Flags } from "store/slices/featureFlags";
import { AppState } from "store/types";
import { extractDynamicSegments } from "utils/routing";
import { WidgetProps } from "./BaseWidget";
import ContainerWidget from "./ContainerWidget";
import WidgetFactory from "./Factory";
import SelectableAndFocusableComponent from "./base/SelectableAndFocusableComponent";
import WidgetNameComponent from "./base/WidgetNameComponent";
import { generatePaddingStyleObject } from "./base/generatePaddingStyle";
import { ZERO_PADDING } from "./base/sizing";
import { useFillParentHeightInfo } from "./base/sizing/dynamicLayoutHooks";
import {
  minMaxWidthPageProperties,
  paddingProperty,
} from "./basePropertySections";
import { getPopoverConfig } from "./eventHandlerPanel";
import { WidgetLayoutProps } from "./shared";

const SelectableAndFocusableComponentWrapper: React.FC<{
  widgetProps: any;
  children: any;
}> = ({ widgetProps, children }) => {
  const isResizingOrDragging = useSelector(
    (state: AppState) =>
      state.legacy.ui.widgetDragResize.isDragging ||
      state.legacy.ui.widgetDragResize.isResizing,
  );

  return (
    <SelectableAndFocusableComponent
      widget={widgetProps}
      parentRectInset={-1}
      key={widgetProps.widgetId}
      disabled={isResizingOrDragging}
    >
      {children}
    </SelectableAndFocusableComponent>
  );
};

class PageWidget extends ContainerWidget {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "Routes",
        sectionCategory: PropsPanelCategory.Routing,
        hidden(props, path, flags) {
          return !flags[Flag.ENABLE_MULTIPAGE];
        },
        children: [
          {
            propertyName: "routes",
            label: "Routes",
            headerControlType: "ADD_ROUTE",
            controlType: "ROUTE_LIST",
            helpText: "The URL routes that will be mapped to this page",
            isBindProperty: false,
            isTriggerProperty: false,
            panelConfig: {
              title: "Edit route",
              editableTitle: false,
              panelIdPropertyName: "ADD_ROUTE",
              isDeletable: true,
              docLink:
                "https://docs.superblocks.com/applications/multi-page/routes",
              getItemId(originalItemId, panelProps) {
                if (!panelProps?.routeId) return originalItemId;
                return panelProps.routeId as string;
              },
              getItemKind() {
                return ItemKinds.ROUTE;
              },
              children: [
                {
                  sectionName: "Route path",
                  showHeader: false,
                  headerType: "Large",
                  children: [
                    {
                      label: "Route path",
                      hideLabel: true,
                      controlType: "ROUTE_PATH_EDITOR",
                      propertyName: "path",
                      isBindProperty: false,
                      isTriggerProperty: false,
                      children: [],
                    },
                  ],
                },
                {
                  sectionName: "Editor fallback parameters",
                  showHeader: false,
                  headerType: "Large",
                  getAdditionalHiddenData: {
                    routes: getRoutes,
                  },
                  helpText:
                    "The editor uses the most recent URL parameters when possible, but falls back if not found. Not used in deployed apps.",
                  hidden: (props, path, flags, additionalHiddenData) => {
                    const routes = additionalHiddenData?.routes;
                    const routeId = props.id;
                    if (!routes || !routeId) return true;

                    const route = routes[routeId as string];
                    if (!route) return true;

                    const paths = extractDynamicSegments(route.path);
                    return paths.length === 0;
                  },
                  children: [
                    {
                      label: "Editor fallback parameters",
                      helpText:
                        "The editor uses the most recent URL parameters when possible, but falls back if not found. Not used in deployed apps.",
                      controlType: "ROUTE_TEST_PARAMS",
                      propertyName: "testParams",
                      isBindProperty: false,
                      isTriggerProperty: false,
                      children: [],
                    },
                  ],
                },
                {
                  sectionName: "Event handlers",
                  children: [
                    getPopoverConfig(
                      "onRouteLoad",
                      "Triggered when the route is navigated to, including within the same page. Runs before the onPageLoad event.",
                      {
                        label: "onRouteLoad",
                      },
                      undefined,
                      true,
                      "onRouteLoad",
                    ),
                  ],
                  hidden(
                    _props: any,
                    _propertyPath: string,
                    featureFlags: Flags,
                  ) {
                    return !featureFlags[Flag.MULTIPAGE_ROUTE_EVENTS];
                  },
                },
              ],
            },
          },
        ],
      },
      {
        sectionName: "Sections",
        sectionCategory: PropsPanelCategory.Content,
        children: [
          {
            propertyName: "children",
            label: "Sections",
            headerControlType: "ADD_SECTION",
            controlType: "CHILD_LIST",
            isBindProperty: false,
            isTriggerProperty: false,
            childFilter: (child) =>
              child.type !== WidgetTypes.MODAL_WIDGET &&
              child.type !== WidgetTypes.SLIDEOUT_WIDGET,
          },
        ],
      },
      {
        sectionName: "Style",
        children: [paddingProperty(), ...minMaxWidthPageProperties()],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        hidden(_props: any, _propertyPath: string, featureFlags: Flags) {
          return !featureFlags[Flag.ENABLE_CUSTOM_PAGE_LOAD_ACTIONS];
        },
        children: [
          getPopoverConfig(
            "onPageLoad",
            "Actions that run once the page loads",
            {
              headerControlType: "RERUN_CONTROL",
            },
          ),
        ],
      },
    ];
  }

  getWidgetType = () => {
    return WidgetTypes.PAGE_WIDGET;
  };

  renderChildWidget(childData: WidgetLayoutProps): React.ReactNode {
    if (!childData) return null;

    // SECTION_WIDGETs are detached from the layout for now so that
    // we can migrate to >= v7 DSL without the end user seeing any changes
    if (childData.type === WidgetTypes.SECTION_WIDGET) {
      childData.detachFromLayout = true;
    }

    return WidgetFactory.createWidget(childData, this.props.appMode);
  }

  renderChildren = () => {
    let children = map(this.props.children ?? [], (childData) =>
      this.renderChildWidget({
        ...childData,
        resizeDisabled: true,
      }),
    );

    if (this.props.appMode === APP_MODE.EDIT) {
      children = [
        this.wrapInSelectableAndFocusableComponent(
          children,
          `${this.props.widgetId}_children`,
        ),
      ];
    }
    return children;
  };

  wrapInSelectableAndFocusableComponent = (children: any, key: string) => {
    return (
      <SelectableAndFocusableComponentWrapper
        widgetProps={this.props}
        key={key}
      >
        {children}
      </SelectableAndFocusableComponentWrapper>
    );
  };

  getPageView() {
    if (this.props.appMode === APP_MODE.EDIT) {
      return (
        <>
          {this.wrapInSelectableAndFocusableComponent(
            <PageWidgetPageView
              {...this.props}
              widgetId={this.props.widgetId}
              renderChildren={this.renderChildren}
              appMode={this.props.appMode}
              pageChildren={this.props.children}
            />,
            `${this.props.widgetId}_root`,
          )}
        </>
      );
    }
    return (
      <PageWidgetPageView
        {...this.props}
        widgetId={this.props.widgetId}
        renderChildren={this.renderChildren}
        appMode={this.props.appMode}
        pageChildren={this.props.children}
      />
    );
  }
}

// Types
type PageWidgetPageViewProps = {
  widgetId: string;
  appMode: APP_MODE;
  renderChildren: () => React.ReactNode[];
  pageChildren: WidgetLayoutProps["children"];
};

// Styled components
const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

// Internal components
const InternalEmptySpaceOverlay = ({
  widgetId,
  widgetChildren,
}: {
  widgetId: string;
  widgetChildren?: WidgetProps[];
}) => {
  const focusedWidget = useSelector(getFocusedWidget);
  const isFocused = focusedWidget?.widgetId === widgetId;

  return (
    <EmptySpaceOverLay isFocused={isFocused} childrenWidgets={widgetChildren} />
  );
};

const InternalWidgetName = ({
  widgetId,
  widgetChildren,
  isModalOrSlideoutOpen,
  ...props
}: {
  widgetId: string;
  widgetChildren: WidgetLayoutProps["children"];
  isModalOrSlideoutOpen: boolean;
} & WidgetProps) => {
  const focusedWidget = useSelector(getFocusedWidget);
  const isFirstSectionFocused =
    focusedWidget?.widgetId === widgetChildren?.[0]?.widgetId;

  return (
    <WidgetNameComponent
      {...props}
      widgetId={widgetId}
      widgetType={WidgetTypes.PAGE_WIDGET}
      hasInvalidProps={false}
      errorMessage={""}
      showNameOverride={false}
      hideNameOverride={isFirstSectionFocused || isModalOrSlideoutOpen}
    />
  );
};

// Main component
const PageWidgetPageView = (props: PageWidgetPageViewProps & WidgetProps) => {
  const { widgetId, renderChildren, appMode, padding } = props;
  const pagePadding = padding ?? ZERO_PADDING;

  const isModalOrSlideoutOpen = useSelector(modalOrSlideoutIsOpen);
  const [containsFillParentSection] = useFillParentHeightInfo();
  useStickySectionOnParentScroll(MODAL_TARGET_ID);

  const pageStyle = useMemo(() => {
    return {
      ...generatePaddingStyleObject(pagePadding),
      minHeight: containsFillParentSection
        ? `calc(100vh - ${FP_BOTTOM_PADDING}px)`
        : "calc(100vh - 2px)",
    };
  }, [pagePadding, containsFillParentSection]);

  if (appMode === APP_MODE.EDIT) {
    return (
      <>
        <PageContainer
          className={`${generateClassName(widgetId)} ${CLASS_NAMES.STYLED_SCROLLBAR}`}
          style={pageStyle}
        >
          {renderChildren()}
          <InternalEmptySpaceOverlay
            widgetId={widgetId}
            widgetChildren={props.children}
          />
          <PaddingOverlay
            padding={pagePadding}
            widgetId={widgetId}
            appMode={props.appMode}
          />
        </PageContainer>
        <InternalWidgetName
          {...props}
          widgetId={widgetId}
          widgetChildren={props.pageChildren}
          isModalOrSlideoutOpen={isModalOrSlideoutOpen}
        />
      </>
    );
  }

  return (
    <PageContainer
      className={`${generateClassName(widgetId)} ${CLASS_NAMES.STYLED_SCROLLBAR}`}
      style={pageStyle}
    >
      {renderChildren()}
    </PageContainer>
  );
};

export default PageWidget;
