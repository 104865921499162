import { set, without } from "lodash";
import React from "react";
import { call, put, select } from "redux-saga/effects";
import {
  setSingleWidget,
  UpdateWidgetPropertiesPayload,
} from "legacy/actions/controlActions";
import { WidgetAddChild } from "legacy/actions/pageActions";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import {
  BASE_WIDGET_VALIDATION,
  VALIDATION_TYPES,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";

import {
  DataTree,
  DataTreeWidget,
} from "legacy/entities/DataTree/dataTreeFactory";
import { getDataTreeWidgetsById } from "legacy/selectors/dataTreeSelectors";
import {
  deleteWithBindingsOrTriggers,
  mergeUpdatesWithBindingsOrTriggers,
} from "legacy/utils/DynamicBindingUtils";
import { fastClone } from "utils/clone";
import { getDottedPathTo } from "utils/dottedPaths";
import { getComponentDimensions } from "utils/size";
import BaseWidget, { RunWidgetEventHandlers } from "../BaseWidget";
import {
  CanvasWidgetsReduxState,
  DerivedPropertiesMap,
  WidgetActionHook,
} from "../Factory";
import withMeta from "../withMeta";
import { KeyValueComponent } from "./KeyValueComponent";
import KeyValueWidgetPropertyCategories from "./KeyValueWidgetPropertyCategories";
import { KeyValueEvaluatedWidgetProps, KeyValueWidgetProps } from "./types";
import {
  getDerivedPropertiesKeys,
  getProperties,
  isDefaultProperty,
} from "./utils";

class KeyValueWidget extends BaseWidget<KeyValueEvaluatedWidgetProps, never> {
  runEventHandlersBound: (payload: RunWidgetEventHandlers) => void;

  constructor(props: KeyValueEvaluatedWidgetProps) {
    super(props);
    this.runEventHandlersBound = this.runEventHandlers.bind(this);
  }

  getPageView() {
    const { componentWidth, componentHeight } = getComponentDimensions(
      this.props,
    );
    return (
      <KeyValueComponent
        widgetId={this.props.widgetId}
        sourceData={this.props.sourceData}
        properties={this.props.properties}
        styleProps={this.props.styleProps}
        propertiesOrder={this.props.propertiesOrder}
        keyProps={this.props.keyProps}
        valueProps={this.props.valueProps}
        runEventHandlers={this.runEventHandlersBound}
        componentWidth={componentWidth}
        componentHeight={componentHeight}
        isLoading={this.props.isLoading}
      />
    );
  }

  static applyActionHook: WidgetActionHook = function* (params: {
    widgetId: string;
    widgets: Readonly<CanvasWidgetsReduxState>;
    action: ReduxAction<
      DataTree | WidgetAddChild | UpdateWidgetPropertiesPayload
    >;
  }) {
    const { widgetId, widgets, action } = params;
    if (widgets[widgetId].type !== WidgetTypes.KEY_VALUE_WIDGET) {
      return;
    }
    switch (action.type) {
      case ReduxActionTypes.TREE_WILL_UPDATE: {
        const currentUnevaluatedWidget: KeyValueWidgetProps = widgets[
          widgetId
        ] as KeyValueWidgetProps;

        const currentEvaluatedWidget: KeyValueEvaluatedWidgetProps = (
          action.payload as DataTree
        ).PAGE[
          currentUnevaluatedWidget.widgetName
        ] as unknown as KeyValueEvaluatedWidgetProps;

        const previousEvaluatedWidgetsById: Record<string, DataTreeWidget> =
          yield select(getDataTreeWidgetsById);
        const previousEvaluatedWidget = previousEvaluatedWidgetsById[
          widgetId
        ] as unknown as KeyValueEvaluatedWidgetProps | undefined;

        if (!previousEvaluatedWidget || !currentEvaluatedWidget.sourceData) {
          // do not modified the properties on first load or if sourceData is undefined
          return;
        }

        const previousSourceDataKeys = Object.values(
          previousEvaluatedWidget.properties,
        )
          .filter((property) => property && !property?.isDerived)
          .map((property) => property.key);

        const newSourceDataKeys = Object.keys(
          currentEvaluatedWidget.sourceData ?? {},
        );

        // if the sourceData has not changed and every property is present, we can return
        if (
          previousSourceDataKeys.length === newSourceDataKeys.length &&
          previousSourceDataKeys.every((key) =>
            newSourceDataKeys.includes(key),
          ) &&
          newSourceDataKeys.every(
            (key) => currentEvaluatedWidget.properties[key],
          )
        ) {
          return;
        }

        const updates: Record<string, unknown> = {};
        const propertiesToAdd: string[] = without(
          newSourceDataKeys,
          ...previousSourceDataKeys,
        );

        const propertiesToDelete: string[] = without(
          previousSourceDataKeys,
          ...newSourceDataKeys,
        );

        // we need to recalculate the properties and the properties order
        let updatedWidget = fastClone(currentUnevaluatedWidget);

        const newCalculatedProperties = getProperties(
          currentEvaluatedWidget.sourceData ?? {},
          currentUnevaluatedWidget.properties, // we need to use the properties without the bindings resolved
          currentUnevaluatedWidget.cachedProperties,
        );

        propertiesToAdd.forEach((id: string) => {
          updates[`properties${getDottedPathTo(id)}`] =
            newCalculatedProperties[id];
        });

        updates["propertiesOrder"] = [
          ...newSourceDataKeys,
          ...getDerivedPropertiesKeys(currentUnevaluatedWidget.properties),
        ];

        const propertyUpdates = (yield call(
          mergeUpdatesWithBindingsOrTriggers,
          currentUnevaluatedWidget,
          KeyValueWidgetPropertyCategories,
          updates,
          true,
        )) as unknown as Record<string, unknown>;

        Object.entries(propertyUpdates).forEach(
          ([propertyPath, propertyValue]) => {
            set(updatedWidget, propertyPath, propertyValue);
          },
        );

        // delete the properties that are no longer in the sourceData
        if (propertiesToDelete.length) {
          updatedWidget = (yield call(
            deleteWithBindingsOrTriggers,
            updatedWidget,
            propertiesToDelete.map((id) => `properties${getDottedPathTo(id)}`),
          )) as unknown as KeyValueWidgetProps;
        }

        // cache the properties that will be deleted and are different from the default property
        propertiesToDelete.forEach((id: string) => {
          if (
            currentUnevaluatedWidget.properties[id] &&
            !isDefaultProperty(currentUnevaluatedWidget.properties[id])
          ) {
            if (!updatedWidget.cachedProperties) {
              updatedWidget.cachedProperties = {};
            }
            updatedWidget.cachedProperties[id] =
              currentUnevaluatedWidget.properties[id];
          }
        });

        newSourceDataKeys.forEach((id: string) => {
          delete updatedWidget?.cachedProperties?.[id];
        });

        yield put(
          setSingleWidget(
            currentEvaluatedWidget.widgetId,
            updatedWidget,
            false,
            false,
          ),
        );
      }
    }
  };

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      data: /*javascript*/ `{{ Object.values(this.properties).reduce((accum, property) => {
        let value = property.computedValue || (this.sourceData && this.sourceData[property.key]);

        // Same logic as in KeyValuePropertyValue, the isValidNumber function.
        if ((property.type === "percentage" || property.type === "number" || property.type === "currency") && 
          typeof value === "string"
        ) {
          const trimmed = value.trim();
          if (!trimmed) {
            value = undefined;
          } else {
            const num = Number(trimmed);
            value = (!isNaN(num) && isFinite(num)) ? parseFloat(value) : NaN;
          }
        }

        accum[property.key] = value;
        return accum;
      }, {}) }}`,
    };
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      sourceData: VALIDATION_TYPES.KEY_VALUE_DATA,
    };
  }

  getWidgetType(): WidgetType {
    return "KEY_VALUE_WIDGET";
  }

  static getNewPropertyPaneConfig():
    | PropertyPaneConfig<KeyValueWidgetProps>[]
    | undefined {
    return KeyValueWidgetPropertyCategories;
  }

  static getPropertyPaneConfig(): PropertyPaneConfig<KeyValueWidgetProps>[] {
    throw new Error("Deprecated config should not be called");
  }
}

export default KeyValueWidget;

export const ConnectedKeyValueWidget = withMeta(KeyValueWidget);
